<template>
  <div class="record">
    <!-- 导航栏 充值记录-->
    <van-nav-bar :title="$t('recharge.depositHistory')" class="record_nav"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('recharge.depositHistory') }}</span>
      </template>
    </van-nav-bar>

    <van-tabs v-model="active" swipeable line-width="15px">
      <!-- 全部记录 -->
      <van-tab :title="$t('recharge.selectItem')">
        <div v-if="lists.length > 0">
          <van-cell v-for="value in lists" :key="value.id">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{ value.type }}</span>
                <span v-if="value.status === '已完成'" class="list-item-title-sub">（{{ value.status === '已完成' ?
      $t('recharge.complete')
      : '' }}）</span>
                <span v-else class="list-item-title-sub-green">（{{
      value.status === '待审核' ? $t('recharge.pending') : ''
    }}）</span>
              </div>
              <span class="list-item-indate">{{
        (value.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
      }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('recharge.applicationNumber') }}:{{ value.payid }}</div>
                <span class="list-item-money">{{ $money(value.money) }}</span>
                <span v-if="value.addMoney > 0" class="list-item-money">({{ $t('recharge.realization') }}
                  {{ $money(value.addMoney) }})</span>
              </div>
            </template>
          </van-cell>
        </div>
        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('recharge.thereCurrentlyRecords') }}</div>
      </van-tab>

      <!-- 已完成 -->
      <van-tab :title="$t('recharge.success')">
        <div v-if="listDown.length > 0">
          <van-cell v-for="value in listDown" :key="value.id">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{ value.type }}</span>
                <span v-if="value.status === '已完成'" class="list-item-title-sub">（{{ value.status === '已完成' ?
      $t('recharge.complete')
      : '' }}）</span>
                <span v-else class="list-item-title-sub-green">（{{
      value.status === '待审核' ? $t('recharge.pending') : ''
    }}）</span>
              </div>
              <span class="list-item-indate">{{
        (value.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
      }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('recharge.applicationNumber') }}:{{ value.payid }}</div>
                <span class="list-item-money">{{ $money(value.money) }}</span>
                <span v-if="value.addMoney > 0" class="list-item-money">({{ $t('recharge.realization') }} {{ $money(value.addMoney) }})</span>
              </div>
            </template>
          </van-cell>
        </div>
        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('recharge.thereCurrentlyRecords') }}</div>
      </van-tab>
      <!-- 待审核 -->
      <van-tab :title="$t('recharge.failure')">
        <div v-if="listAudit.length > 0">
          <van-cell v-for="value in listAudit" :key="value.id">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{ value.type }}</span>
                <span v-if="value.status === '已完成'" class="list-item-title-sub">（{{ value.status === '已完成' ?
      $t('recharge.complete')
      : '' }}）</span>
                <span v-else class="list-item-title-sub-green">（{{
      value.status === '待审核' ? $t('recharge.pending') : ''
    }}）</span>
              </div>
              <span class="list-item-indate">{{
        (value.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
      }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('recharge.applicationNumber') }}:{{ value.payid }}</div>
                <span class="list-item-money">{{ $money(value.money) }}</span>
                <span v-if="value.addMoney > 0" class="list-item-money">({{ $t('recharge.realization') }}{{ $money(value.addMoney) }})</span>
              </div>
            </template>
          </van-cell>
        </div>
        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('recharge.thereHistoryCurrently') }}</div>
      </van-tab>
    </van-tabs>

    <div class="list">{{ $t('recharge.noDataAvailable') }}</div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      active: 0,
      lists: [],
      listDown: [],
      listAudit: [],
    }
  },
  mounted() { },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: '/pay/record',
        params: {
          page: 1,
          status: true,
        },
      })
      if (ret === 1) {
        this.lists = data.lists
      }
      this.listDown = this.lists.filter((t) => t.status === '已完成')
      this.listAudit = this.lists.filter((t) => t.status === '待审核')
    },
  },
}
</script>

<style lang="less" scoped>
.record {
  font-family: 'PingFang-Regular';
  padding-top: 92px;

  .record_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      font-family: 'PingFang-Regular';
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-cell {
    box-sizing: border-box;
    padding: 20px 30px;

    /deep/ .van-cell__title {
      flex: none;
      width: 260px;

      .list-item-title {
        width: 240px;

        .list-item-title-text {
          font-size: 26px;
          color: #242424;
        }

        .list-item-title-sub,
        .list-item-title-sub-green {
          font-size: 24px;
          color: #dc1323;
        }

        .list-item-title-sub-green {
          color: #1bb019;
        }
      }

      .list-item-indate {
        margin-top: 12px;
        font-size: 24px;
        color: #888888;
      }
    }

    /deep/ .van-cell__value {
      .list-item-right {
        .list-item-order {
          width: 445px;
          font-size: 16px !important;
          color: #999999;
        }

        .list-item-money {
          font-size: 34px;
          color: #dc1323;
        }
      }
    }
  }

  .nolist {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 24px;
    color: #999999;
  }

  .list {
    margin: 50px 0;
    text-align: center;
    font-size: 28px;
  }
}
</style>
